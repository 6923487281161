@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "M PLUS Rounded 1c";
}

body {
  @apply bg-light-100 dark:bg-dark-900;
}
